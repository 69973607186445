import { AlertColor } from '@mui/material';
import useLinksQuery from '_api/Links';
import { Button, Form, InputText, Page, PageHeading } from 'components';
import Notification from 'components/notification';
import { axiosPost } from 'helpers';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import { t } from 'i18next';
import { ErrorShape } from 'pages/auth/login/login-page';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface ResetPasswordFormInputs {
  Email: string;
}
export interface StatusType {
  severity: string;
  message: string;
}

const RequestPasswordResetPage: FC = () => {
  const { control, handleSubmit } = useForm<ResetPasswordFormInputs>();
  const { data: endpoints } = useLinksQuery();
  const [status, setStatus] = useState<StatusType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const requestResetPasswordLink = getApiHrefByRel(
    endpoints ?? [],
    'request-password-reset',
  );
  const onSubmit = async (data: ResetPasswordFormInputs) => {
    if (requestResetPasswordLink) {
      setLoading(true);
      setStatus(null);
      try {
        const response = await axiosPost(requestResetPasswordLink, data);

        if (response?.status === 204) {
          setStatus({
            severity: 'success',
            message: 'Email successfully sent to mail id.',
          });
        }
      } catch (error) {
        const errorObj = error as ErrorShape;
        if (errorObj.status === 400) {
          const errorMessages = Object.keys(errorObj?.data?.Errors).map((key) =>
            errorObj?.data?.Errors[key].map((message: string) => message),
          );

          const flattenedErrorMessages = errorMessages
            .reduce((acc, val) => acc.concat(val), [])
            .filter(Boolean);
          setStatus({
            severity: 'error',
            message: flattenedErrorMessages.join(' ').toString(),
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <Page>
      {status && (
        <Notification
          isAutoClosing={true}
          onClose={() => {
            setStatus(null);
          }}
          severity={status ? (status.severity as AlertColor) : 'success'}
        >
          {status.message}
        </Notification>
      )}

      <PageHeading heading="Reset Password" />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="Email"
          control={control}
          defaultValue=""
          render={() => (
            <InputText
              label={t('Email')}
              type="email"
              control={control}
              name="Email"
              rules={{
                required: t('Email field is required.'),
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: t('Please enter valid email address'),
                },
              }}
            />
          )}
        />

        <Button
          type="submit"
          loading={loading}
          disabled={status ? true : false}
        >
          Submit
        </Button>
      </Form>
    </Page>
  );
};

export default RequestPasswordResetPage;
