import { TranslatedString } from './no';

const seJSON: { [key in TranslatedString]: string } = {
  // Languages
  Login: 'Logga in',
  'Language Switcher': 'Språkväxling',
  English: 'Engelsk',
  Norwegian: 'Norsk',
  Swedish: 'Svenska',
  'Total amount (Including Tax)': 'Totalt belopp (inklusive skatt)',

  'Go back': 'Gå tillbaka',
  'Pay with card': 'Betala med kort',
  'Choose Payment Method': 'Välj betalsätt',

  Start: 'Start',
  Continue: 'Fortsätta',

  'Registration number': 'Registreringsnummer',
  Submit: 'Skicka',

  'Parking details': 'Parkeringsdetaljer',
  'Send recipe': 'Skicka recept',
  Home: 'Hem',
  'Add Vehicle': 'Lägg till fordon',
  'Edit Vehicle': 'Redigera fordon',

  'All set to go': 'Allt klart att köra',
  'Payment failed': 'Betalning misslyckades',

  'License plate': 'Registreringsskylt',

  'Total cost': 'Total kostnad',
  'Try again': 'Försök igen',

  'Parking area': 'Parkeringsplass',
  Amount: 'Belopp',
  Started: 'Satte igång',
  Duration: 'Varighet',
  Price: 'Pris',
  ' at ': ' kl. ',

  'Access terminal': 'Åtkomstterminal',

  Security: 'säkerhet',
  'Enter your registration number': 'Ange ditt registreringsnummer',
  Next: 'Nästa',
  'There is no parking on {{RegistrationNumber}}. Are you sure you have written correctly?':
    'Det finns ingen parkering på {{RegistrationNumber}}. Är du säker på att du har skrivit rätt?',

  Pay: 'Betala',
  'There are no parking sessions for your license plate number {{thePlateNumber}}':
    'Det finns inga parkeringssessioner för ditt registreringsnummer {{thePlateNumber}}',
  Refresh: 'Uppdatera',

  'Parking sessions were outdated. We’ve updated the new details now.':
    'Parkeringssessioner var föråldrade. Vi har uppdaterat de nya detaljerna nu.',
  'Payment aborted': 'Betalning avbruten',

  'Parking is now paid and we want you to be safe until your next destination.':
    'Parkering är nu betald och vi vill att du ska vara säker till din nästa destination.',

  'Terms and Conditions': 'Villkor',
  'No terms and conditions for operator ID {{operatorGuid}} with language code {{langCode}}':
    'Inga villkor för operatörs-ID {{operatorGuid}} med språkkod {{langCode}}',

  'Total (Incl. Tax)': 'Totalt (inkl. moms)',

  'Payment complete': 'Betalning komplett',
  'Request receipt': 'Begära kvitto',
  'Email address': 'E-postadress',
  'Send receipt': 'Skicka kvitto',
  'Something went wrong with sending the receipt':
    'Något gick fel med att skicka kvittot',
  'Your receipt will be sent to your inbox shortly':
    'Kvitteringen vil bli sendt til innboksen din innen kort tid',
  'Please enter valid email address': 'Ange giltig e -postadress',
  'Successfully paid <b>{{total}}</b> to <b>{{operatorName}}</b>':
    'Framgångsrikt betalas <b> {{total}} </b> till <b> {{operationName}} </b>',

  'Successfully paid <b>{{total}}</b>':
    'Framgångsrikt betalas <b> {{total}} </b>',
  'Complete payment': 'Fullständig betalning',

  'Incomplete payments': 'Ofullständiga betalningar',
  at: 'på',

  Registration: 'Registrering',
  Register: 'Registrera',

  'First Name': 'Förnamn',
  'First Name field is required.': 'Fältets förnamn krävs.',
  'Last Name': 'Efternamn',
  'Last Name field is required.': 'Fältets efternamn krävs.',
  Email: 'E-post',
  'Email field is required.': 'E -postfält krävs.',
  'Please enter valid email address.': 'Ange giltig e -postadress.',
  'Mobile Phone': 'Mobiltelefon',
  'Mobile Phone field is required.': 'Mobiltelefonfält krävs.',
  'Country Code': 'Landskod',
  'Country Code field is required.': 'Landskodsfält är obligatoriskt.',
  Password: 'Lösenord',
  'Password field is required.': 'Lösenordsfält krävs.',
  'Confirm Password': 'Bekräfta lösenord',
  'Confirm Password field is required.': 'Bekräfta lösenordsfältet krävs.',
  'Your passwords did not match': 'Dina lösenord matchade inte',
  'Password & Confirm Password does not match':
    'Lösenord och bekräfta lösenordet matchar inte',
  Language: 'Språk',
  'Language field is required.': 'Språkfält krävs.',
  'Allow marketing emails': 'Tillåt e -postmeddelanden till marknadsföring',
  'Accept terms of use': 'Acceptera användarvillkor',
  'See here': 'Kolla här',
  'User has been register successfully.':
    'Användaren har registrerats framgångsrikt.',
  'User has not registered due to some Unexpected Error':
    'Användaren har inte registrerat sig på grund av något oväntat fel',
  'Terms Of Use': 'Villkor',
  'Invalid license plate': 'Ogiltig registreringsskylt',
  Country: 'land',
  'Country field is required': 'Fältet Land är obligatoriskt',
  loading: 'läser in',
  'Email verified successfully. Redirecting to login...':
    'E-posten har verifierats. Omdirigerar till inloggning...',
  'Error verifying email. Please try again.':
    'Fel vid verifiering av e-post. Var god försök igen.',
  'Reset Password': 'Återställ lösenord',
  'Set Password': 'Välj lösenord',
  'Email is successfully sent to mail id.': 'E-post har skickats till mail-id.',
  'Apply for membership': 'Ansök om medlemskap',
  'Already have an account?': 'Har du redan ett konto?',
  'Login here': 'Logga in här',
  'Forgot password?': 'Glömt ditt lösenord?',
  'Dont have an account?': 'Har du inget konto?',
  'Create one here': 'Skapa en här',
  'Password cannot exceed 15 characters':
    'Lösenordet får inte överstiga 15 tecken',
  'Password must be at least 5 characters long':
    'Lösenordet måste vara minst 5 tecken långt',
  'Please enter password': 'Ange lösenord Tack',
  'Invalid email address': 'Ogiltig e-postadress',
  'Please enter email': 'Vänligen ange e-post',
  'Vehicle added successfully': 'Fordonet har lagts till',
  'Please enter License plate': 'Vänligen ange registreringsskylt',
  'Please enter description': 'Vänligen ange beskrivning',
  'Description cannot exceed 15 characters':
    'Beskrivningen får inte överstiga 15 tecken',
  'Vehicle updated successfully': 'Fordonet har uppdaterats',
  'Error while updating vehicle': 'Fel vid uppdatering av fordonet',
  '+ Add Vehicle': '+ Lägg till fordon',
  Vehicles: 'Fordon',
  Memberships: 'Medlemskap',
  'Password reset successfully, click here to login':
    'Lösenordsåterställningen lyckades, klicka här för att logga in',
  'Please enter new password': 'Vänligen ange nytt lösenord',
  Apply: 'Tillämpa',
  'Membership Plans': 'Medlemskapsplaner',
  cancel: 'Avbryt',
  'Add Comment': 'Lägg till kommentar',
  Comment: 'Kommentar',
  'Write your comment here ...': 'Skriv din kommentar här...',
  'Add subscription': 'Lägg till prenumeration',
  'Subscription plans': 'Prenumerationsplaner',
  Subscribe: 'Prenumerera',
  Description: 'Beskrivelse',
  // Menu items
  Dashboard: 'Dashboard',
  Membership: 'Medlemskap',
  Subscriptions: 'Abonnement',
  Payment: 'Betaling',
  Logout: 'Logga av',

  'Payment needs to be completed': 'Betalingen må gjennomføres',
  'within 15 minutes': 'innen 15 minutter',

  Loading: 'Laster',
  'Payment was cancelled': 'Betalingen ble kansellert',
  'Payment was successful': 'Betalingen var vellykket',
};

export default seJSON;
