export * from './landing/landing-page';
export * from './parking-details/parking-details-page';
export * from './payment-method/payment-method-page';
export * from './payment-details/payment-details-page';
export * from './pay-later/pay-later-page';
export * from './confirmation/confirmation-page';

export enum Routes {
  Landing = '/',
  Vehicles = '/vehicles',
  ParkingDetails = '/parking-details',
  PaymentMethod = '/payment-method',
  PaymentDetails = '/payment-details',
  PayLater = '/pay-later',
  Confirmation = '/confirmation-page',
  Security = '/security',
  ParkingAvailability = '/security/:identifier',
  PaymentComplete = '/payment-complete',
  TermsAndConditions = '/terms-and-conditions',
  AccountRegistration = '/register',
  AccountLogin = '/login',
}
