export interface ISvgProps extends React.SVGProps<SVGSVGElement> {
  pathClassName?: string;
}

export enum SvgIcons {
  CreditCard = 'SvgIconCreditCard',
  CheckCircle = 'SvgIconCheckCircle',
  ArrowRight = 'SvgIconArrowRight',
  Spinner = 'SvgIconSpinner',
  CarBike = 'SvgIconCarBike',
  NeedHelp = 'SvgIconNeedHelp',
  UserAccount = 'SvgIconUserAccount',
  CarSvg = 'SvgIconCar',
}

export interface VehicleData {
  carName: string;
  numberPlate: string;
  paymentInfo: string;
  pic: string;
}

export interface CarData {
  vehicleName: string;
  description: string;
}
