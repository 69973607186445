import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ParkingSessionsApiResponse } from 'interfaces';

type State = {
  apiResponse: ParkingSessionsApiResponse | undefined;
  isLoading: boolean;
};

const initialState: State = {
  apiResponse: undefined,
  isLoading: true,
};

const parkingSessionsSlice = createSlice({
  name: 'parking-sessions-response',
  initialState,
  reducers: {
    setResponse: (
      state,
      action: PayloadAction<ParkingSessionsApiResponse | undefined>,
    ) => {
      state.isLoading = false;
      state.apiResponse = action.payload;
    },
  },
});

export const { setResponse: setParkingSessionsResponse } =
  parkingSessionsSlice.actions;

export default parkingSessionsSlice.reducer;
