import { SvgIcon, SvgIcons } from 'shared';
import './_loader.scss';
import { FC } from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  loading?: boolean;
  className?: string;
}

const defaultProps: Props = {
  loading: false,
  className: 'loader',
};

export const Loader: FC<Props> = (props) => {
  props = { ...defaultProps, ...props };
  const { loading, className, children } = props;

  if (loading) {
    return (
      <div className={className}>
        <SvgIcon icon={SvgIcons.Spinner} width={25} />
      </div>
    );
  } else {
    return <>{children}</>;
  }
};
