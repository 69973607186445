import { Heading5, Heading6 } from 'components';

import { FC } from 'react';

import './_parking-session-detail.scss';

type Props = {
  heading?:
    | 'Parking area'
    | 'Started'
    | 'Duration'
    | 'Price'
    | 'License plate'
    | 'Amount'
    | 'Total amount (Including Tax)';
  content?: string | number;
  textAlign?: 'left' | 'right' | 'center';
};

const ParkingSessionDetail: FC<Props> = (props: Props) => {
  if (!props || !props.content) {
    return null;
  }
  const { heading, content, textAlign } = props;

  const baseClassName = 'parking-session-detail';

  return (
    <>
      <div
        className={`${baseClassName} ${baseClassName}-${heading}`}
        style={{ textAlign }}
      >
        <Heading6 className={`${baseClassName}__heading`}>{heading}</Heading6>
        <Heading5 className={`${baseClassName}__content`}>{content}</Heading5>
      </div>
    </>
  );
};

export default ParkingSessionDetail;
