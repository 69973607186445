import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Alignment, ButtonTheme } from 'static/constants';
import { SvgCtaContent, SvgIcons } from 'shared';
import './_link-button-to.scss';
import { FC } from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  to: string;
  iconName?: SvgIcons;
  iconWidth?: number;
  iconPosition?: Alignment;
  formatClassName?: string;
  theme?: ButtonTheme;
  loading?: boolean;
}

// Link tag with button styles/format
export const LinkButtonTo: FC<Props> = (props) => {
  const {
    className,
    formatClassName,
    theme = ButtonTheme.Default,
    to,
    iconName,
    iconPosition,
    iconWidth,
    loading,
    children,
  } = props;

  const baseClassName = 'link-button-to button';
  const componentClassName = classNames(
    className,
    baseClassName,
    formatClassName,
    theme,
  );

  return (
    <Link to={to} className={componentClassName}>
      <SvgCtaContent
        iconName={iconName}
        iconPosition={iconPosition}
        iconWidth={iconWidth}
        loading={loading}
      >
        {children}
      </SvgCtaContent>
    </Link>
  );
};
