interface ISiteConfig {
  pageTitle: string;
  pageTitlePrefix: string;
  settings: ISiteConfigSettings;
}

interface ISiteConfigSettings {
  numberOfSteps: number;
}

export const siteConfig: ISiteConfig = {
  pageTitle: 'Byr',
  pageTitlePrefix: '-',
  settings: {
    numberOfSteps: 4,
  },
};

export default siteConfig;
