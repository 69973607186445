import { useLocation, Outlet } from 'react-router-dom';
import VehicleList from 'pages/vehicle/list';
import './dashboard-page.scss';

const DashboardPage = (): JSX.Element => {
  const location = useLocation();

  return (
    <div className="dashboard-page">
      {location.pathname === '/dashboard' ? <VehicleList /> : <Outlet />}
    </div>
  );
};

export default DashboardPage;
