import { Grid } from '@mui/material';
import { Button } from 'components/clickers/button/button';
import { t } from 'i18next';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import MembershipModal from './Modal/MembershipModal';
import useLinksQuery from '_api/Links';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import { parseTemplate } from 'url-template';
import { axiosGet } from 'helpers';
import {
  FacilityMembership,
  FacilityMembershipApiResponse,
} from 'interfaces/membership';
import MembershipPlanCard from './MembershipPlanCard';
import { Loader } from 'components/loader/loader';
import { PageHeading } from 'components/layout/page/page-heading';

const Membership: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: endpoints } = useLinksQuery();
  const [loading, setLoading] = useState<boolean>(false);

  const [facilityMemberships, setFacilityMemberships] =
    useState<FacilityMembership[]>();

  const facilityMembershipLink = useMemo(
    () => getApiHrefByRel(endpoints ?? [], 'facility-memberships'),
    [endpoints],
  );
  const getFacilityMemberships = useCallback(async () => {
    try {
      if (facilityMembershipLink) {
        const urlTemplate = parseTemplate(decodeURI(facilityMembershipLink));
        const apiLink = urlTemplate.expand({
          pagesize: 50,
          page: 0,
        });
        setLoading(true);
        const response = await axiosGet(apiLink);
        const data = response?.data as FacilityMembershipApiResponse;

        setFacilityMemberships(data.Items);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [facilityMembershipLink]);
  useEffect(() => {
    getFacilityMemberships();
  }, [facilityMembershipLink]);

  return (
    <>
      <Grid
        container
        sx={{
          px: 2,
          justifyContent: 'center',
        }}
      >
        <PageHeading heading="Memberships" />

        {loading && (
          <Grid
            container
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              position: 'sticky',
              top: 0,
              marginTop: '10px',
            }}
          >
            <Loader loading={loading}></Loader>
          </Grid>
        )}

        <Grid my={2} width={'100%'}>
          {facilityMemberships?.map((membership) => (
            <MembershipPlanCard
              membership={membership}
              key={membership.MembershipGuid}
            />
          ))}
        </Grid>
        <Button
          type="submit"
          loading={false}
          disabled={false}
          onClick={() => setIsOpen(true)}
        >
          {t('Apply for membership')}
        </Button>
      </Grid>
      {isOpen && (
        <MembershipModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          getFacilityMemberships={getFacilityMemberships}
        />
      )}
    </>
  );
};

export default Membership;
