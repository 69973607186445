import classNames from 'classnames';
import { SvgCtaContent, SvgIcons } from 'shared';
import { Alignment, ButtonTheme } from 'static/constants';
import './_button.scss';
import { FC } from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  iconName?: SvgIcons;
  iconWidth?: number;
  iconPosition?: Alignment;
  formatClassName?: string;
  theme?: ButtonTheme;
  loading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button: FC<Props> = (props) => {
  const {
    className,
    formatClassName,
    theme = ButtonTheme.Default,
    iconName,
    iconPosition,
    iconWidth,
    disabled,
    loading,
    children,
    ...buttonProps
  } = props;

  const baseClassName = 'button';
  const componentClassName = classNames(
    className,
    baseClassName,
    formatClassName,
    theme,
  );

  return (
    <button {...buttonProps} className={componentClassName} disabled={disabled}>
      <SvgCtaContent
        iconName={iconName}
        iconPosition={iconPosition}
        iconWidth={iconWidth}
        loading={loading}
      >
        {children}
      </SvgCtaContent>
    </button>
  );
};
