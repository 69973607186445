import useLinksQuery from '_api/Links';
import { Loader, Page, PageHeading } from 'components';
import { axiosGet } from 'helpers';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import { ISwedbankPayTermsOfServiceApiResponse } from 'interfaces';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useLocation } from 'react-router-dom';
import { parseTemplate } from 'url-template';
import './_terms-and-conditions-page.scss';
import Notification from 'components/notification';

const TermsAndConditionsPage = (): JSX.Element => {
  const { i18n, t } = useTranslation();
  const baseClassName = 'terms-and-conditions-page';
  const { search } = useLocation();
  const { data: endpoints } = useLinksQuery();
  const operatorGuid = new URLSearchParams(search).get('operatorguid');
  const [termsOfServiceContent, setTermsOfServiceContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const getLanguageIsoCode = (langCode: string) => {
    switch (langCode) {
      case 'no':
        return 'nb-NO';
      case 'se':
        return 'sv-SE';
      default:
        return 'en-US';
    }
  };

  useEffect(() => {
    if (operatorGuid && endpoints) {
      const swedbankPayTermsOfServiceLink = getApiHrefByRel(
        endpoints ?? [],
        'swedbank-pay-terms-of-service',
      );

      if (swedbankPayTermsOfServiceLink) {
        const urlTemplate = parseTemplate(
          decodeURI(swedbankPayTermsOfServiceLink),
        );
        const apiLink = urlTemplate.expand({
          operatorguid: operatorGuid,
          language: getLanguageIsoCode(i18n.language),
        });

        setIsLoading(true);
        axiosGet<ISwedbankPayTermsOfServiceApiResponse>(apiLink)
          .then((response) => {
            if (response?.data.TermsOfService) {
              setTermsOfServiceContent(response?.data.TermsOfService);
            }
          })
          .catch(() => {
            setTermsOfServiceContent('');
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, [endpoints, i18n.language, operatorGuid]);

  return (
    <Page className={baseClassName}>
      <PageHeading heading="Terms and Conditions" />

      {isLoading ? (
        <Loader loading={true} className="loader page-loading" />
      ) : (
        <div className={`${baseClassName}__content`}>
          {termsOfServiceContent ? (
            <ReactMarkdown>{termsOfServiceContent}</ReactMarkdown>
          ) : (
            <Notification severity="error">
              {t(
                'No terms and conditions for operator ID {{operatorGuid}} with language code {{langCode}}',
                {
                  operatorGuid: operatorGuid,
                  langCode: getLanguageIsoCode(i18n.language),
                },
              )}
            </Notification>
          )}
        </div>
      )}
    </Page>
  );
};

export default TermsAndConditionsPage;
