import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

import no from './translations/no';
import se from './translations/se';

const resources = {
  no: { translation: no },
  se: { translation: se },
};

let language = window.localStorage.getItem('Language');
if (!language) {
  language = String(navigator.language).substring(0, 2);
  window.localStorage.setItem('Language', language);
}

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  fallbackLng: language,
  keySeparator: '.',
  debug: false,
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
  },
  compatibilityJSON: 'v3',
  returnNull: true,
});

export default i18n;
