import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Notification from 'components/notification';
import { axiosPost } from 'helpers';
import useLinksQuery from '_api/Links';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import { Loader } from 'components';
import { t } from 'i18next';
import { Grid } from '@mui/material';

const VerifyEmailPage: FC = () => {
  const { data: endpoints } = useLinksQuery();
  const location = useLocation();
  const navigate = useNavigate();

  const [verificationStatus, setVerificationStatus] = useState<
    'loading' | 'success' | 'error'
  >('loading');

  useEffect(() => {
    const verifyEmailLink = getApiHrefByRel(endpoints ?? [], 'verify-email');

    if (verifyEmailLink) {
      const verifyEmail = async () => {
        try {
          const searchParams = new URLSearchParams(location.search);

          const email = searchParams.get('email');
          const code = searchParams.get('code');
          const payload = {
            Code: code,
            Email: email,
          };
          const verify = await axiosPost(verifyEmailLink, payload);
          if (verify?.status === 200) {
            setVerificationStatus('success');
            setTimeout(() => {
              navigate('/login');
            }, 3000);
          } else {
            setVerificationStatus('error');
          }
        } catch (error) {
          setVerificationStatus('error');
        }
      };
      verifyEmail();
    } else {
      setVerificationStatus('error');
    }
  }, [endpoints, navigate, location.search]);

  return (
    <div>
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
        {verificationStatus === 'loading' && (
          <Loader loading={verificationStatus === 'loading'}></Loader>
        )}
      </Grid>
      {verificationStatus === 'success' && (
        <Notification severity="success">
          {t('Email verified successfully. Redirecting to login...')}
        </Notification>
      )}
      {verificationStatus === 'error' && (
        <Notification severity="error">
          {t('Error verifying email. Please try again.')}
        </Notification>
      )}
    </div>
  );
};

export default VerifyEmailPage;
