import { RouteObject, useRoutes } from 'react-router-dom';
import commonRouter from './common/common-router';
import guestRouter from './guest/guest-router';
import privateRouter from './private/private-router';

const routes: RouteObject[] = [
  { ...commonRouter },
  { ...guestRouter },
  { ...privateRouter },
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Router = () => useRoutes(routes);

export default Router;
