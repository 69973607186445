import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface User {
  Email: string;
  FirstName: string;
  IsEmailVerified: boolean;
  Language: string;
  LastName: string;
  Links: { Href: string; Rel: string }[];
}

interface InitialState {
  status: boolean;
  user: User;
}

const initialState: InitialState = {
  status: false,
  user: {
    Email: '',
    FirstName: '',
    IsEmailVerified: false,
    Language: '',
    LastName: '',
    Links: [{ Href: '', Rel: '' }],
  },
};

const userAuthSlice = createSlice({
  name: 'userAuthSlice',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<User>) => {
      state.status = true;
      state.user = action.payload;
    },
    logout: () => {
      return initialState;
    },
  },
});

export const { login, logout } = userAuthSlice.actions;
export default userAuthSlice.reducer;
