import React, { ReactNode } from 'react';
import { Modal, styled } from '@mui/material';
import { Page } from 'components/layout/page/page';

const ModalContent = styled('div')({
  background: 'rgb(255, 216, 63)',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  padding: '20px',
  margin: '20px',
  borderRadius: '8px',
});

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface ModalWrapperProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  open,
  onClose,
  children,
}) => {
  return (
    <Page>
      <StyledModal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ModalContent>{children}</ModalContent>
      </StyledModal>
    </Page>
  );
};

export default ModalWrapper;
