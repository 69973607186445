import Layout from 'components/layout/layout';
import siteConfig from 'hooks/site-config';
import 'i18n/i18n';

import './App.scss';
import Router from 'router';
import { FC } from 'react';
const App: FC = () => {
  document.title = siteConfig.pageTitle;

  return (
    <Layout>
      <Router />
    </Layout>
  );
};

export default App;
