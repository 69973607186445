import classNames from 'classnames';
import './_heading4.scss';
import { FC } from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  formatClassName?: string;
}

export const Heading4: FC<Props> = (props) => {
  if (!props || !props.children) {
    return null;
  }
  const { className, formatClassName, children, ...heading4Props } = props;

  const baseClassName = 'heading4';
  const componentClassName = classNames(
    className,
    baseClassName,
    formatClassName,
  );

  return (
    <h4 {...heading4Props} className={componentClassName}>
      {children}
    </h4>
  );
};
