import useLinksQuery from '_api/Links';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

type Props = {
  children: ReactNode;
};

const GuestRouterWrapper: FC<Props> = ({ children }) => {
  const { data } = useLinksQuery();
  const isLoggedIn = !!getApiHrefByRel(data ?? [], 'user-account');
  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }
  return <>{children}</>;
};

export default GuestRouterWrapper;
