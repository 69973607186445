import { Outlet, RouteObject } from 'react-router-dom';
import PrivateRouterWrapper from './private-router-wrapper';
import DashboardPage from 'pages/dashboard';
import Membership from 'components/membership';
import VehicleList from 'pages/vehicle/list';
import SubscriptionPage from 'pages/subscription-page';
const privateRouter: RouteObject = {
  element: (
    <PrivateRouterWrapper>
      <Outlet />
    </PrivateRouterWrapper>
  ),
  children: [
    {
      path: 'dashboard',
      element: <DashboardPage />,
      children: [
        {
          path: 'vehicle',
          element: <VehicleList />,
        },
        {
          path: 'membership',
          element: <Membership />,
        },
        {
          path: 'subscriptions',
          element: <SubscriptionPage />,
        },
      ],
    },
  ],
};

export default privateRouter;
