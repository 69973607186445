import useLinksQuery from '_api/Links';
import { Button, Form, InputText, Loader, Page, PageHeading } from 'components';
import VehicleCard from 'components/vehicle/VehicleCard';
import { axiosGet, axiosPut } from 'helpers';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import { parseTemplate } from 'url-template';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, ResponseData, Vehicle } from './types';
import { AlertColor, Grid } from '@mui/material';
import ModalWrapper from 'components/modal/ModalWrapper';
import { Controller, useForm } from 'react-hook-form';
import { singleVehicleResponse } from '../types';
import CloseIcon from '@mui/icons-material/Close';
import { StatusType } from 'pages/request-reset-password/RequestPasswordResetPage';
import Notification from 'components/notification';
import AddVehicleForm from '../add-vehicle';
import { t } from 'i18next';

import './VehicleList.scss';

const VehicleList: FC = () => {
  const [vehicles, setVehicles] = useState<ResponseData>({
    Items: [],
    Links: [],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUpdatevehicle, setLoadingUpdatevehicle] =
    useState<boolean>(false);
  const { control, handleSubmit, setValue } = useForm<Vehicle>();
  const { data: endpoints } = useLinksQuery();
  const [open, setOpen] = useState(false);
  const [openAddVehicle, setOpenAddVehicle] = useState(false);
  const [status, setStatus] = useState<StatusType | null>(null);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAddVehicle = () => {
    setOpenAddVehicle(false);
  };
  const getAllVehicleLink = useMemo(
    () => getApiHrefByRel(endpoints ?? [], 'vehicles'),
    [endpoints],
  );
  const getAllVehicles = useCallback(async () => {
    try {
      if (getAllVehicleLink) {
        const urlTemplate = parseTemplate(decodeURI(getAllVehicleLink));
        const apiLink = urlTemplate.expand({
          pagesize: 20,
          page: 0,
        });
        setLoading(true);
        const response = await axiosGet(apiLink);
        const data = response?.data as ResponseData;
        setVehicles(data);
      }
    } catch (error) {
      setStatus({
        severity: 'error',
        message: 'Error while getting vehicles',
      });
    } finally {
      setLoading(false);
    }
  }, [getAllVehicleLink]);

  useEffect(() => {
    getAllVehicles();
  }, [getAllVehicleLink, getAllVehicles]);

  const onSubmit = async (data: Vehicle) => {
    try {
      setLoadingUpdatevehicle(true);
      const singleVehicleLink = getApiHrefByRel(data?.Links ?? [], 'self');
      const vehicle = await axiosGet(singleVehicleLink);
      const vehicleResponse = vehicle?.data as singleVehicleResponse;
      const updateDescriptionLink = getApiHrefByRel(
        vehicleResponse?.Links ?? [],
        'description',
      );
      await axiosPut(updateDescriptionLink, {
        Description: data.Description,
      });
      setLoadingUpdatevehicle(false);
      handleClose();
      setStatus({
        severity: 'success',
        message: t('Vehicle updated successfully'),
      });
      getAllVehicles();
    } catch (error) {
      setStatus({
        severity: 'error',
        message: t('Error while updating vehicle'),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (vehicle: Vehicle) => {
    setValue('VehicleGuid', vehicle.VehicleGuid);
    setValue('Description', vehicle.Description);
    setValue('LicensePlate', vehicle.LicensePlate);
    setValue('Links', vehicle.Links);
    setOpen(true);
  };
  return (
    <Page className="vehicle-list-tab-content">
      <PageHeading heading="Vehicles" />
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
        }}
      >
        {status && (
          <Notification
            isAutoClosing={true}
            onClose={() => {
              setStatus(null);
            }}
            severity={status ? (status.severity as AlertColor) : 'success'}
          >
            {status.message}
          </Notification>
        )}
        {loading && <Loader loading={loading}></Loader>}
      </Grid>

      {vehicles?.Items?.map((vehicle: Vehicle) => (
        <VehicleCard
          vehicleData={vehicle}
          key={vehicle.VehicleGuid}
          onEdit={handleEdit}
        />
      ))}
      <Button onClick={() => setOpenAddVehicle(true)} type="submit">
        {t('+ Add Vehicle')}
      </Button>
      <>
        {/* Edit vehicle modal */}

        <Grid>
          <ModalWrapper open={open} onClose={handleClose}>
            <Grid
              justifyContent={'center'}
              alignItems={'center'}
              display={'flex'}
              container
            >
              <Grid item xs={12} sx={{ textAlign: 'end' }}>
                <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <PageHeading heading="Edit Vehicle" />
              </Grid>
            </Grid>
            <Form
              onSubmit={handleSubmit(onSubmit)}
              className="add-vehicle-form"
            >
              <Controller
                name="LicensePlate"
                control={control}
                defaultValue=""
                render={() => (
                  <InputText
                    label="License plate"
                    type="text"
                    readOnly={true}
                    control={control}
                    name="LicensePlate"
                    required
                    rules={{
                      required: t('Please enter License plate'),
                    }}
                  />
                )}
              />
              <Controller
                name="Description"
                control={control}
                defaultValue=""
                render={() => (
                  <InputText
                    label="Description"
                    type="text"
                    control={control}
                    required
                    name="Description"
                    rules={{
                      required: t('Please enter description'),
                      maxLength: {
                        value: 15,
                        message: t('Description cannot exceed 15 characters'),
                      },
                    }}
                  />
                )}
              />
              <Button type="submit" loading={loading} disabled={loading}>
                {loadingUpdatevehicle ? (
                  <Loader loading={loadingUpdatevehicle}></Loader>
                ) : (
                  t('Submit')
                )}
              </Button>
            </Form>
          </ModalWrapper>
        </Grid>

        {/* Add vehicle modal */}

        <ModalWrapper open={openAddVehicle} onClose={handleCloseAddVehicle}>
          <Grid
            justifyContent={'center'}
            alignItems={'center'}
            display={'flex'}
            container
          >
            <Grid
              item
              xs={7}
              sx={{ textAlign: 'end', paddingBottom: 1 }}
            ></Grid>
            <Grid item xs={4} sx={{ textAlign: 'end' }}>
              <CloseIcon
                sx={{ cursor: 'pointer' }}
                onClick={handleCloseAddVehicle}
              />
            </Grid>
            <Grid item sm={6}>
              <AddVehicleForm
                Links={vehicles?.Links as Link[]}
                status={setStatus}
                onClose={handleCloseAddVehicle}
                callback={getAllVehicles}
              />
            </Grid>
          </Grid>
        </ModalWrapper>
      </>
    </Page>
  );
};

export default VehicleList;
