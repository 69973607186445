import { Grid, Typography } from '@mui/material';
import { Heading6 } from 'components/headings/heading6/heading6';
import { FacilityMembership } from 'interfaces/membership';
import { FC } from 'react';
import './MembershipPlanCard.scss';
interface Props {
  membership: FacilityMembership;
}
const MembershipPlanCard: FC<Props> = ({ membership }) => {
  const { PlanName, Status, CompanyName } = membership;

  return (
    <Grid className="membership-list-item" container alignItems={'start'}>
      <Grid>
        <Heading6>{PlanName}</Heading6>
        <Typography component="p" className="secondary-text">
          {CompanyName}
        </Typography>
      </Grid>

      <Grid>
        <Heading6
          style={{
            fontSize: '16px',
            color:
              Status === 'Active'
                ? '#358935'
                : Status === 'Application rejected'
                ? '#eb1d1d'
                : '#df5e00',
          }}
        >
          {Status}
        </Heading6>
      </Grid>
    </Grid>
  );
};

export default MembershipPlanCard;
