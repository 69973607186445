import {
  Button,
  Form,
  FormGroup,
  InputText,
  Loader,
  Page,
  PageHeading,
} from 'components';
import Notification from 'components/notification';
import { ensureLicenseNumberFormat } from 'helpers/input-validation-helper';
import queryString from 'query-string';
import { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Routes } from 'pages';
import './_landing-page.scss';

interface IForm {
  inputRegistrationNumber: string;
  inputCountries: string;
}

const defaultValues = {
  inputRegistrationNumber: '',
  inputCountries: 'NOR',
};

export function LandingPage(): JSX.Element {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<IForm>({ defaultValues: defaultValues });
  const { control, handleSubmit, setValue } =
    methods; /*  reset, setValue, watch */
  const [searchParams] = useSearchParams();
  const operatorGuid = searchParams.get('operatorguid');
  const carParkGuid = searchParams.get('carparkguid');
  const register = searchParams.get('register');
  const navigate = useNavigate();

  // TODO: Refactor later on. Right now is for demo purposes
  const onSubmit = (data: IForm) => {
    setIsLoading(true);

    const queryParams = queryString.stringify(
      {
        operatorguid: operatorGuid,
        carparkguid: carParkGuid,
        regNo: ensureLicenseNumberFormat(data.inputRegistrationNumber),
      },
      {
        skipNull: true,
      },
    );

    navigate(Routes.ParkingDetails + '?' + queryParams);
  };

  const baseClassName = 'landing-page';

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue('inputRegistrationNumber', ensureLicenseNumberFormat(value));
  };

  return (
    <Page className={baseClassName}>
      <PageHeading heading="Start" />

      {register === 'success' && (
        <Notification severity="success">
          {t('User has been register successfully.')}
        </Notification>
      )}
      {register === 'failed' && (
        <Notification severity="error" variant="filled">
          {t('User has not registered due to some Unexpected Error')}
        </Notification>
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup formatClassName="dropdown-combo">
          <InputText
            name="inputRegistrationNumber"
            label={t('Registration number')}
            control={control}
            required
            onValueChange={handleChange}
            maxLength={8}
          />
        </FormGroup>
        <Button type="submit">
          <Loader loading={isLoading}>{t('Submit')}</Loader>
        </Button>
      </Form>
    </Page>
  );
}
