import { combineReducers } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';

import LinksApi from '_api/Links/LinksApi';
import { parkingSessionsReducer } from './parking-sessions';
import { userAuthSlice } from './auth';

const reducer = combineReducers({
  links: LinksApi.reducer,
  parkingSessionsReducer: parkingSessionsReducer,
  auth: userAuthSlice,
});

export const middleware = [thunkMiddleware, LinksApi.middleware];

export default reducer;
