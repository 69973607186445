/**
 * Ensures correct format by removing all unallowed characters
 * when the user tries to add an un-allowed character
 */
export const ensureLicenseNumberFormat = (value: string): string =>
  value
    /**
     * Removes all empty spaces from value
     */
    .replace(' ', '')
    /**
     * Converts text to upper case
     */
    .toLocaleUpperCase()
    /**
     * Removes everything that is not:
     * - a letter
     * - a norwegian letter
     * - a number
     */
    .replaceAll(/[^A-Z0-9\WÆÅØ]/gi, '');
