import classNames from 'classnames';
import './_form-group.scss';
import { FC } from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  formatClassName?: string;
}

export const FormGroup: FC<Props> = (props) => {
  const { formatClassName = 'default', className, children } = props;

  const baseClassName = 'form-group';
  const componentClassName = classNames(
    baseClassName,
    formatClassName,
    className,
  );

  return <div className={componentClassName}>{children}</div>;
};
