import useLinksQuery from '_api/Links';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import { FC, ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

type Props = {
  children: ReactNode;
};

const PrivateRouterWrapper: FC<Props> = ({ children }) => {
  const { data, refetch } = useLinksQuery();

  useEffect(() => {
    refetch();
  }, [refetch, data]);

  const isLoggedIn = !!getApiHrefByRel(data ?? [], 'user-account');
  if (!isLoggedIn && data?.length) {
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
};

export default PrivateRouterWrapper;
