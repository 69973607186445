// Need to use the React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  credentials: 'include',
});

export type ApiLink = {
  Href: string;
  Rel: string;
};

// Define a service using a base URL and expected endpoints
export const LinksApi = createApi({
  reducerPath: 'links',
  baseQuery: baseQuery,
  tagTypes: ['links'],
  endpoints: (builder) => ({
    links: builder.query<ApiLink[], void>({
      query: () => 'byr-connect',
      providesTags: ['links'],
      transformResponse: (response: { Links: ApiLink[] }) =>
        response.Links ?? [],
    }),
  }),
});

export const { useLinksQuery } = LinksApi;

export default LinksApi;
