export enum ButtonTheme {
  Default = 'default',
  Dark = 'dark',
  Ghost = 'ghost',
}

export enum AnchorTheme {
  Default = 'default',
}

export enum Alignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

export enum Countries {
  NOR = 'NOR',
  SWE = 'SWE',
}

export const countries = [
  {
    label: Countries.NOR,
    value: Countries.NOR,
  },
  {
    label: Countries.SWE,
    value: Countries.SWE,
  },
];

export const EmailRegex =
  // eslint-disable-next-line no-control-regex
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
