import { Outlet, RouteObject } from 'react-router-dom';
import GuestRouterWrapper from './guest-router-wrapper';

import RegisterPage from 'pages/auth/register';
import LoginPage from 'pages/auth/login';
import VerifyEmailPage from 'pages/verify-email';
import RequestPasswordResetPage from 'pages/request-reset-password/RequestPasswordResetPage';
import PasswordResetPage from 'pages/password-reset';

const guestRouter: RouteObject = {
  element: (
    <GuestRouterWrapper>
      <Outlet />
    </GuestRouterWrapper>
  ),
  children: [
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'verify-email',
      element: <VerifyEmailPage />,
    },
    {
      path: 'request-reset-password',
      element: <RequestPasswordResetPage />,
    },
    {
      path: 'reset-password',
      element: <PasswordResetPage />,
    },
  ],
};

export default guestRouter;
