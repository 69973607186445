import { Grid } from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EditIcon from '@mui/icons-material/Edit';
import { Heading4 } from 'components/headings/heading4/heading4';
import { Vehicle } from 'pages/vehicle/list/types';
import { FC } from 'react';
import { Heading6 } from 'components/headings/heading6/heading6';

interface VehicleCardProps {
  vehicleData: Vehicle;
  onEdit: (data: Vehicle) => void;
}

const VehicleCard: FC<VehicleCardProps> = ({ vehicleData, onEdit }) => {
  const { LicensePlate, Description } = vehicleData;
  return (
    <Grid container>
      <Grid item xs={2}>
        <DirectionsCarIcon fontSize="large" />
      </Grid>
      <Grid container item xs={8}>
        <Grid textAlign="left" item xs={12}>
          <Heading4>{LicensePlate}</Heading4>
        </Grid>
        <Grid item textAlign="left" xs={12}>
          <Heading6>{Description}</Heading6>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <EditIcon
          sx={{ cursor: 'pointer' }}
          fontSize="small"
          onClick={() => onEdit(vehicleData)}
        />
      </Grid>
    </Grid>
  );
};

export default VehicleCard;
