import { ICarPark, IParkingSession } from 'interfaces';
import { FC, useMemo } from 'react';
import ParkingSessionRow from '../session';

import './_car-park-group.scss';

type Props = {
  data: ICarPark;
};

const CarParkGroup: FC<Props> = ({ data }) => {
  const { Address, Name, ParkingSessions } = data;

  const timezone = Address.IanaTimeZone;
  const address = useMemo(() => {
    if (!Address) {
      return '';
    }
    const street = Address.Street.replace('-', '');
    const zipCode = Address.ZipCode.replace('-', '');
    const city = Address.City.replace('-', '');

    if (street && city && zipCode) {
      return ` | ${street}, ${zipCode} ${city}`;
    }
    if (city && zipCode) {
      return ` | ${zipCode} ${city}`;
    }

    if (city) {
      return ` | ${city}`;
    }

    return '';
  }, [Address]);

  if (!ParkingSessions || !ParkingSessions.length) {
    return <></>;
  }

  return (
    <div className="car-park-section">
      <div className="car-park-section-heading">
        <span className="car-park-name">{Name}</span>
        <span className="car-park-address">{address}</span>
      </div>
      <div className="car-park-section-sessions">
        {ParkingSessions.map((session: IParkingSession, index: number) => (
          <ParkingSessionRow data={session} timezone={timezone} key={index} />
        ))}
      </div>
    </div>
  );
};

export default CarParkGroup;
