import { AlertColor } from '@mui/material';
import useLinksQuery from '_api/Links';
import { Button, Form, InputText, Page, PageHeading } from 'components';
import Notification from 'components/notification';
import { axiosPost } from 'helpers';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import { t } from 'i18next';
import { ErrorShape } from 'pages/auth/login/login-page';
import { StatusType } from 'pages/request-reset-password/RequestPasswordResetPage';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

interface PasswordResetPageFormInputs {
  NewPassword: string;
  ConfirmPassword: string;
}

const PasswordResetPage: FC = () => {
  const methods = useForm<PasswordResetPageFormInputs>();
  const { control, handleSubmit, watch } = methods;
  const { data: endpoints } = useLinksQuery();
  const [error, setError] = useState<ErrorShape | null>(null);
  const [status, setStatus] = useState<StatusType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const passwordResetLink = getApiHrefByRel(endpoints ?? [], 'reset-password');
  const onSubmit = async (data: PasswordResetPageFormInputs) => {
    if (data.NewPassword !== data.ConfirmPassword) {
      return setError({
        data: {
          Message: 'Error',
          Errors: {
            error: [t('Your passwords did not match')],
          },
        },
        status: 400,
        statusText: '',
      });
    }

    if (passwordResetLink) {
      setLoading(true);
      setError(null);
      try {
        const searchParams = new URLSearchParams(location.search);
        const email = searchParams.get('email');
        const code = searchParams.get('code');
        const payload = {
          Email: email,
          Code: code,
          Password: data.NewPassword.trim(),
        };
        const response = await axiosPost(passwordResetLink, payload);
        if (response?.status === 204) {
          setStatus({
            severity: 'success',
            message: t('Password reset successfully, click here to login'),
          });
        }
      } catch (error) {
        const errorObj = error as ErrorShape;
        if (errorObj.status === 400) {
          setError(errorObj);
        }
      } finally {
        setLoading(false);
      }
    } else {
      setError({
        data: {
          Message: 'Error',
          Errors: {
            error: ['Something Went Wrong!'],
          },
        },
        status: 404,
        statusText: '',
      });
    }
  };
  return (
    <Page>
      {error && (
        <Notification
          isAutoClosing={true}
          onClose={() => {
            setError(null);
          }}
          severity="error"
        >
          {Object.keys(error?.data?.Errors).map((key, index) => (
            <div key={index}>
              {error?.data?.Errors[key].map((message, i) => (
                <div key={i}>{message}</div>
              ))}
            </div>
          ))}
        </Notification>
      )}
      {status && (
        <Notification
          isAutoClosing={false}
          onClose={() => {
            setStatus(null);
          }}
          severity={status.severity as AlertColor}
          onClick={() => navigate('/login')}
          sx={{ cursor: 'pointer' }}
        >
          {status.message}
        </Notification>
      )}

      <PageHeading heading="Reset Password" />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="NewPassword"
          control={control}
          defaultValue=""
          render={() => (
            <InputText
              label="New Password"
              type="password"
              control={control}
              name="NewPassword"
              rules={{
                required: t('Please enter new password'),
              }}
            />
          )}
        />
        <Controller
          name="ConfirmPassword"
          control={control}
          defaultValue=""
          render={() => (
            <InputText
              label="Confirm Password"
              type="password"
              control={control}
              name="ConfirmPassword"
              rules={{
                required: t('Confirm Password field is required.'),
                validate: (val: string) => {
                  if (watch('NewPassword') !== val) {
                    return t('Password & Confirm Password does not match');
                  }
                },
              }}
            />
          )}
        />

        <Button type="submit" loading={loading} disabled={loading}>
          Submit
        </Button>
      </Form>
    </Page>
  );
};

export default PasswordResetPage;
