import { LinkAnchorTo, LinkButtonTo, Page, PageHeading } from 'components';
import { useTranslation } from 'react-i18next';
import { Routes } from 'pages';

import './_payment-method-page.scss';
export function PaymentMethodPage(): JSX.Element {
  const { t } = useTranslation();
  const baseClassName = 'payment-method-page';

  return (
    <Page className={baseClassName}>
      <PageHeading heading="Choose Payment Method" />
      <LinkButtonTo to={Routes.PaymentDetails}>Vipps</LinkButtonTo>
      <LinkButtonTo to={Routes.PaymentDetails}>Nets</LinkButtonTo>
      <LinkAnchorTo to={Routes.ParkingDetails}>{t('Go back')}</LinkAnchorTo>
    </Page>
  );
}
