import { FC, useEffect, useState, useRef, useCallback } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { Button } from 'components/clickers/button/button';
import { Loader } from 'components/loader/loader';
import useLinksQuery from '_api/Links';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import { parseTemplate } from 'url-template';
import { axiosGet } from 'helpers';

import SubscriptionModal from 'components/subscription/Modal';
import SubscriptionPlanCard from 'components/subscription/subscription-plan-card/SubscriptionPlanCard';
import {
  SubscribedPlan,
  SubscribedPlansApiResponse,
} from 'interfaces/subscription';
import Notification from 'components/notification';
import { PageHeading } from 'components';

const SubscriptionPage: FC = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<SubscribedPlan[]>([]);
  const { data: endpoints } = useLinksQuery();
  const fetchedRef = useRef(false);
  const searchParams = new URLSearchParams(location.search);
  const isPaymentCancelled = searchParams.get('is_cancelled');
  const isPaymentSuccess = searchParams.get('is_completed');

  const subscriptionLink = endpoints
    ? getApiHrefByRel(endpoints, 'subscriptions')
    : null;
  const subscriptionPlansLink = endpoints
    ? getApiHrefByRel(endpoints, 'subscription-plans')
    : '';

  const fetchSubscriptions = useCallback(async () => {
    if (!subscriptionLink) return;
    try {
      setLoading(true);
      fetchedRef.current = true;
      const urlTemplate = parseTemplate(decodeURI(subscriptionLink));
      const apiLink = urlTemplate.expand({ pagesize: 50, page: 0 });
      const response = await axiosGet(apiLink);
      const data = response?.data as SubscribedPlansApiResponse;

      setSubscriptions(data?.Items ?? []);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    } finally {
      setLoading(false);
    }
  }, [subscriptionLink]);

  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);

  return (
    <Grid container sx={{ px: 2, justifyContent: 'center', display: 'flex' }}>
      <PageHeading heading="Subscriptions" />
      <br />
      <br />
      {isPaymentCancelled && (
        <Notification
          isAutoClosing={false}
          severity={'error'}
          sx={{ cursor: 'pointer' }}
        >
          {t('Payment was cancelled')}
        </Notification>
      )}
      {isPaymentSuccess && (
        <Notification
          isAutoClosing={false}
          severity={'success'}
          sx={{ cursor: 'pointer' }}
        >
          {t('Payment was successful')}
        </Notification>
      )}

      {loading && (
        <Grid
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            position: 'sticky',
            top: 0,
            marginTop: '10px',
          }}
        >
          <Loader loading={loading} />
        </Grid>
      )}
      <Grid my={2} width={'100%'}>
        {subscriptions?.map((subscription) => (
          <SubscriptionPlanCard
            subscription={subscription}
            key={subscription.SubscriptionGuid}
          />
        ))}
      </Grid>
      <Grid display={'flex'} pl={3}>
        <Button
          type="submit"
          loading={false}
          disabled={false}
          onClick={() => setIsAddModalOpen(true)}
        >
          {t('Add subscription')}
        </Button>
      </Grid>
      {isAddModalOpen && (
        <SubscriptionModal
          isOpen={isAddModalOpen}
          setIsOpen={setIsAddModalOpen}
          subscriptionPlansLink={subscriptionPlansLink}
          refreshSubscriptions={fetchSubscriptions}
        />
      )}
    </Grid>
  );
};

export default SubscriptionPage;
