import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Heading5, LinkAnchorTo } from 'components';
import useLinksQuery from '_api/Links';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import { axiosPost } from 'helpers';
import LinksApi from '_api/Links/LinksApi';
import './_header.scss';
import { useTranslation } from 'react-i18next';
import { TranslatedString } from 'i18n/translations/no';

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>;

type MenuItem = { title: TranslatedString; path: string };

const MenuDrawer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: endpoints } = useLinksQuery();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setIsLoggedIn(Boolean(getApiHrefByRel(endpoints || [], 'sign-out')));
  }, [endpoints]);

  const signOut = async () => {
    const linkToSignOut = getApiHrefByRel(endpoints || [], 'sign-out');

    if (linkToSignOut) {
      const response = await axiosPost(linkToSignOut, {});
      if (response?.status === 200) {
        dispatch(LinksApi.util.invalidateTags(['links']));
        setIsLoggedIn(false);
      }
    }
  };

  const getMenuItem = (title: TranslatedString, path: string): MenuItem => ({
    title,
    path,
  });

  const menuItems: MenuItem[] = isLoggedIn
    ? [
        getMenuItem('Dashboard', '/dashboard'),
        getMenuItem('Vehicles', '/dashboard/vehicle'),
        getMenuItem('Membership', '/dashboard/membership'),
        getMenuItem('Subscriptions', '/dashboard/subscriptions'),
        getMenuItem('Payment', '/'),
        getMenuItem('Logout', '/logout'),
      ]
    : [getMenuItem('Login', '/login'), getMenuItem('Payment', '/')];

  const toggleDrawer = (newOpen: boolean) => () => setOpen(newOpen);

  const handleItemClick = (item: { title: string; path: string }) => {
    if (item.title === 'Logout') {
      signOut();
    } else {
      navigate(item.path);
    }
    setOpen(false);
  };

  return (
    <div>
      <MenuIcon
        fontSize="large"
        sx={{ cursor: 'pointer' }}
        onClick={toggleDrawer(true)}
      />
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250, height: '100vh', background: 'rgb(255, 216, 63)' }}
          role="presentation"
        >
          <List>
            {menuItems.map((item) => (
              <ListItem key={item.title} disablePadding>
                <ListItemButton onClick={() => handleItemClick(item)}>
                  <ListItemText primary={t(item.title)} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

export const Header: FC<Props> = ({ children }) => (
  <header className="header">
    <div className="user_account">
      <MenuDrawer />
    </div>
    <Heading5 className="header__title">
      <LinkAnchorTo to="/" className="app_name">
        <strong>Byr</strong> Connect
      </LinkAnchorTo>
    </Heading5>
    {children}
  </header>
);
