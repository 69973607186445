import { FC, ChangeEvent, FocusEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './MultiLineInput.scss';
import { TranslatedString } from 'i18n/translations/no';

type PropsType = {
  classes?: string;
  label: TranslatedString;
  value?: string | number;
  id?: string;
  handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  handleFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  handleBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  placeholder?: TranslatedString;
  error?: string;
  readonly?: boolean;
  autoComplete?: 'on' | 'off';
  testId?: string;
};
const MultiLineInput: FC<PropsType> = ({
  id,
  classes,
  label,
  value,
  handleChange,
  handleFocus,
  handleBlur,
  placeholder,
  error,
  readonly = false,
  autoComplete = 'off',
  testId,
}) => {
  const { t } = useTranslation();
  const inputId = id ?? 'multi-line-input';

  const inputBoxClasses = `multi-line-input-box`;

  useEffect(() => {
    const inputArea = document.getElementById(inputId);
    if (inputArea) {
      inputArea.style.height = inputArea.scrollHeight - 30 + 'px';
    }
  }, [value]);

  const autoGrow = (event: ChangeEvent<HTMLTextAreaElement>) => {
    event.target.style.height = '0px';
    event.target.style.height = event.target.scrollHeight - 30 + 'px';
  };

  return (
    <div className={`${inputBoxClasses} ${classes ? classes : ''}`}>
      <div className="input-label">{t(label)}</div>
      <div
        data-cy="input-value"
        className={`input-value ${error ? 'input-error' : ''}`}
      >
        <textarea
          id={inputId}
          className="visible-input"
          onChange={handleChange}
          placeholder={placeholder ? t(placeholder) : ''}
          readOnly={readonly}
          onInput={autoGrow}
          autoComplete={autoComplete}
          onFocus={handleFocus}
          onBlur={handleBlur}
          data-cy={testId}
          value={value}
        ></textarea>
      </div>
      {error && (
        <span data-cy="error-msg" className="error-msg">
          {error}
        </span>
      )}
    </div>
  );
};

export default MultiLineInput;
