import { AlertProps, Alert } from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface Props extends AlertProps {
  isAutoClosing?: boolean;
}

const makeDefaults = (props: Props): Props => ({
  ...props,
  variant: props.variant ?? 'filled',
  isAutoClosing: props.isAutoClosing ?? true,
});

const Notification: FC<Props> = (props) => {
  props = makeDefaults(props);
  const { isAutoClosing, onClose } = props;

  // Deleting prop as otherwise it will go further to Alert and cause issues
  delete props.isAutoClosing;

  const [isShowing, setIsShowing] = useState(true);

  const milisecondsUntilClosing = isAutoClosing ? 10000 : undefined;
  /**
   * Effect for auto-closing the notification after given timeout
   */
  useEffect(() => {
    const timeout = milisecondsUntilClosing
      ? setTimeout(() => {
          setIsShowing(false);
        }, milisecondsUntilClosing)
      : null;

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [milisecondsUntilClosing]);

  const handleClose = (e: React.SyntheticEvent) => {
    if (typeof onClose === 'function') {
      onClose(e);
    }
    setIsShowing(false);
  };

  if (!isShowing) {
    return <></>;
  }

  return <Alert {...props} onClose={(e) => handleClose(e)} />;
};

export default Notification;
