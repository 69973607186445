import LanguageSwitcher from 'components/language-switcher';
import './_footer.scss';
import { FC } from 'react';

export const Footer: FC = () => {
  const baseClassName = 'footer';

  return (
    <footer className={baseClassName}>
      <LanguageSwitcher />
    </footer>
  );
};
