import classNames from 'classnames';
import React, { FC } from 'react';
import { IconType } from 'react-icons/lib';
import './_icon.scss';

interface IIconProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  iconName?: string;
}

export const Icon: FC<IIconProps> = (props) => {
  const { className, iconName } = props;

  const baseClassName = 'icon';
  const componentClassName = classNames(baseClassName, className, iconName);

  return <i className={componentClassName} />;
};

interface IIconRProps {
  icon: IconType;
  size?: number;
}

export const IconR: FC<IIconRProps> = ({ icon, size = 100 }) => {
  return <>{React.createElement(icon, { size })}</>;
};
