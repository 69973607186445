import { Heading6 } from 'components';
import { LicensePlateSessionsGroup } from 'interfaces';
import { FC } from 'react';
import OperatorParkingSessions from '../operator';
import { useTranslation } from 'react-i18next';

interface Props {
  data: LicensePlateSessionsGroup;
  isUnCompletePaymentOrder?: boolean;
}

const LicensePlateSessions: FC<Props> = ({
  data,
  isUnCompletePaymentOrder,
}) => {
  const { Operators } = data;
  const { t } = useTranslation();
  if (!Operators || !Operators.length) {
    return <></>;
  }

  return (
    <div className="license-plate-parking-sessions">
      {isUnCompletePaymentOrder && (
        <Heading6>- {t('Incomplete payments')} -</Heading6>
      )}
      {Operators.map((operator) => (
        <OperatorParkingSessions
          isUnCompletePaymentOrder={isUnCompletePaymentOrder}
          data={operator}
          key={operator.OperatorGuid}
        />
      ))}
    </div>
  );
};

export default LicensePlateSessions;
