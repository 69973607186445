import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { AnchorTheme } from 'static';
import './_link-anchor-to.scss';
import { FC } from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  formatClassName?: string;
  theme?: AnchorTheme;
  to: string;
}

// Link tag with anchor styles/format
export const LinkAnchorTo: FC<Props> = (props) => {
  if (!props || !props.to) {
    return null;
  }
  const {
    className,
    formatClassName,
    theme = AnchorTheme.Default,
    to,
    children,
  } = props;

  const baseClassName = 'link-anchor-to anchor';
  const componentClassName = classNames(
    baseClassName,
    formatClassName,
    theme,
    className,
  );

  return (
    <Link className={componentClassName} to={to}>
      {children}
    </Link>
  );
};
