import classNames from 'classnames';
import './_form.scss';
import { FC } from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  onSubmit: (e: React.FormEvent) => void;
}

export const Form: FC<Props> = (props) => {
  const { className, children, onSubmit } = props;
  const baseClassName = 'form';
  const componentClassName = classNames(baseClassName, className);

  return (
    <form className={componentClassName} onSubmit={onSubmit}>
      {children}
    </form>
  );
};
