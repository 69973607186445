import CloseIcon from '@mui/icons-material/Close';
import { Dialog, IconButton } from '@mui/material';
import { Heading2 } from 'components';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import './_terms-of-use-modal.scss';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

type Props = {
  termsOfServiceContent: string;
  handleClose: () => void;
};

const TermsOfUseModal: FC<Props> = ({ termsOfServiceContent, handleClose }) => {
  const baseClassName = 'terms-of-use-modal';
  const { t } = useTranslation();

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={handleClose}
      className={baseClassName}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
        style={{ position: 'absolute', right: 20, top: 6 }}
      >
        <CloseIcon />
      </IconButton>

      <Heading2>{t('Terms Of Use')}</Heading2>
      <div className={`${baseClassName}__content`}>
        <ReactMarkdown>{termsOfServiceContent}</ReactMarkdown>
      </div>
    </Dialog>
  );
};

export default TermsOfUseModal;
