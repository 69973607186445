import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { IInputControllerProps, SelectOptionInterface } from 'components';
import { Controller } from 'react-hook-form';
import './_input-select.scss';
import { FC } from 'react';

export const InputSelect: FC<IInputControllerProps> = ({
  name,
  control,
  required,
  options = [],
  className,
  rules,
  label,
  defaultValue,
}) => {
  const generateSingleOptions = () => {
    return options.map((option: SelectOptionInterface) => {
      const { value, label } = option;
      return (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl className="input-select" variant="standard">
      <Controller
        rules={rules}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <InputLabel>{label}</InputLabel>
            <Select
              className={className}
              value={value}
              required={required}
              onChange={onChange}
              error={!!error}
              defaultValue={defaultValue}
            >
              {generateSingleOptions()}
            </Select>
            {error && (
              <FormHelperText className="Mui-error">
                {error.message}
              </FormHelperText>
            )}
          </>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};
