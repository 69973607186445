/**
 * Calculates time difference in user friendly format. for example: 10h 12m
 */
export function getTimeDifference(
  startTime: string,
  endTime: string | undefined = undefined,
): string {
  try {
    const exitTime = endTime
      ? new Date(endTime).getTime()
      : new Date().getTime();

    /**
     * Time difference in miliseconds
     */
    const msDiff = exitTime - new Date(startTime).getTime();
    /**
     * Time difference in minutes
     */
    const mDiff = Math.floor(msDiff / 1000 / 60);

    const hours = Math.floor(mDiff / 60);
    const minutes = mDiff - hours * 60;

    if (!!hours && !!minutes) {
      return `${hours}h ${minutes}m`;
    }

    if (!!hours && !minutes) {
      return `${hours}h`;
    }

    return `${minutes}m`;
  } catch (e) {
    console.error('Error when calculating time diff:', e);
    return 'n/a';
  }
}
