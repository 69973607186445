import {
  LandingPage,
  ParkingDetailsPage,
  PaymentMethodPage,
  Routes,
} from 'pages';
import PaymentCompletePage from 'pages/payment-complete';
import TermsAndConditionsPage from 'pages/terms-and-conditions';
import { RouteObject } from 'react-router-dom';

const commonRouter: RouteObject = {
  children: [
    {
      path: '/',
      element: <LandingPage />,
    },
    {
      path: Routes.ParkingDetails,
      element: <ParkingDetailsPage />,
    },
    {
      path: Routes.PaymentComplete,
      element: <PaymentCompletePage />,
    },
    {
      path: Routes.PaymentMethod,
      element: <PaymentMethodPage />,
    },
    {
      path: Routes.TermsAndConditions,
      element: <TermsAndConditionsPage />,
    },
  ],
};

export default commonRouter;
