import { Grid, Typography } from '@mui/material';
import { axiosGet, axiosPost } from 'helpers';
import { getApiHrefByRel } from 'helpers/api-links-helper';
import { FC, useEffect, useState } from 'react';
import { parseTemplate } from 'url-template';
import CloseIcon from '@mui/icons-material/Close';
import './MembershipModal.scss';
import { Button } from 'components/clickers/button/button';
import { t } from 'i18next';
import { Loader } from 'components/loader/loader';
import useLinksQuery from '_api/Links';
import ModalWrapper from 'components/modal/ModalWrapper';
import { Link } from 'pages/vehicle/list/types';
import { Heading5 } from 'components/headings/heading5/heading5';
import { Heading6 } from 'components/headings/heading6/heading6';
import MultiLineInput from 'components/multi-line-input';

interface Facility {
  FacilityGuid: string;
  Name: string;
  ImageUri: string;
  Links: Link[];
}

interface FacilitiesResponse {
  Items: Facility[];
  Links: Link[];
}

interface MembershipPlan {
  MembershipPlanGuid: string;
  Name: string;
  CompanyName: string;
  Links: Link[];
}

interface MembershipPlanWithFacility {
  Items: MembershipPlan[];
  facilityName: string;
  Links: Link[];
}

interface Props {
  isOpen: boolean;
  getFacilityMemberships: () => void;
  setIsOpen: (val: boolean) => void;
}
interface comments {
  comments: string;
  plan: string | undefined;
}
const MembershipModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  getFacilityMemberships,
}) => {
  const { data: endpoints } = useLinksQuery();
  const [memberships, setMemberships] = useState<MembershipPlanWithFacility[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState<comments>({
    comments: '',
    plan: undefined,
  });
  const [showCommentTextArea, setShowCommentTextArea] = useState({
    show: false,
    forPlan: '',
  });

  useEffect(() => {
    const getFacilities = async () => {
      const facilitiesLink = getApiHrefByRel(
        endpoints || [],
        'search-facilities',
      );
      if (facilitiesLink) {
        const facilities = (await fetchFacilities(
          facilitiesLink,
        )) as Facility[];
        const memberships = await fetchMemberships(facilities);

        setMemberships(memberships);
      }
    };
    getFacilities();
  }, [endpoints]);
  const emptyComment = () => {
    setComments({
      comments: '',
      plan: undefined,
    });
    setShowCommentTextArea({
      show: false,
      forPlan: '',
    });
  };

  const fetchFacilities = async (link: string) => {
    const urlTemplate = parseTemplate(decodeURI(link));
    const facilitiesWithMembershipOnly = urlTemplate.expand({
      membersonly: true,
    });
    const response = await axiosGet(facilitiesWithMembershipOnly);
    const data = response?.data as FacilitiesResponse;
    const facilities = await Promise.all(
      data.Items.map(async (item: Facility) => {
        const selfLink = getApiHrefByRel(item.Links, 'self');
        const facilityData = await axiosGet(selfLink);
        return facilityData?.data;
      }),
    );

    return facilities;
  };

  const fetchMemberships = async (facilities: Facility[]) => {
    const memberships = await Promise.all(
      facilities.map(async (facility) => {
        const membershipPlansLink = getApiHrefByRel(
          facility.Links,
          'membership-plans',
        );
        const urlTemplate = parseTemplate(decodeURI(membershipPlansLink));
        const apiLink = urlTemplate.expand({ pagesize: 20, page: 0 });
        const response = await axiosGet(apiLink);
        return {
          ...(response?.data as MembershipPlanWithFacility),
          facilityName: facility.Name,
        };
      }),
    );
    return memberships;
  };

  const handleApplyForMembership = async (
    plan: MembershipPlan,
    comment: string,
  ) => {
    setIsLoading(true);
    try {
      const membershipSelfLink = getApiHrefByRel(plan.Links, 'self');
      const membershipSelf = await axiosGet(membershipSelfLink);
      const selfData = membershipSelf?.data as MembershipPlan;
      const membershipApplyLink = getApiHrefByRel(selfData.Links, 'apply');
      const isAppliedResponse = await axiosPost(membershipApplyLink, {
        Comments: comment || '',
      });
      if ((isAppliedResponse?.status as number) === 201) {
        emptyComment();
        getFacilityMemberships();
        setIsOpen(false);
      }
    } catch (error) {
      emptyComment();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalWrapper open={isOpen} onClose={() => setIsOpen(false)}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="main"
      >
        <Grid item xs={12} textAlign="center" paddingBottom={1}>
          <Heading5>{t('Membership Plans')}</Heading5>
        </Grid>
        {isLoading && <Loader loading={isLoading} />}
        <Grid item xs={2} textAlign="end" className="close-icon">
          <CloseIcon
            style={{ cursor: 'pointer' }}
            fontSize="small"
            onClick={() => setIsOpen(false)}
          />
        </Grid>
        <Grid item xs={12} className="membership">
          {memberships.map((membership) => (
            <Grid
              key={membership.facilityName}
              className="membership-container"
            >
              {membership.Items.length > 0 && (
                <Heading5>{membership.facilityName}</Heading5>
              )}
              <Grid pt={2} className="membership-list">
                {membership.Items.map((plan) => (
                  <Grid key={plan.Name} className="membership-list-item">
                    <Grid>
                      <Heading6>{plan.Name}</Heading6>
                      <Typography component="p" className="secondary-text">
                        {plan.CompanyName}
                      </Typography>
                      {!(showCommentTextArea.forPlan == plan.Name) && (
                        <Typography
                          component={'p'}
                          className="secondary-text"
                          onClick={() =>
                            setShowCommentTextArea({
                              show: true,
                              forPlan: plan.Name,
                            })
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          {'+' + t('Add Comment')}
                        </Typography>
                      )}
                      {showCommentTextArea.forPlan == plan.Name && (
                        <Grid container direction="column" py={1}>
                          <MultiLineInput
                            value={comments.comments}
                            placeholder="Write your comment here ..."
                            label="Comment"
                            handleChange={(e) =>
                              setComments({
                                comments: e.target.value,
                                plan: plan.Name,
                              })
                            }
                          />
                          <Typography
                            component={'p'}
                            className="secondary-text"
                            onClick={emptyComment}
                            style={{ cursor: 'pointer' }}
                          >
                            {t('cancel')}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Button
                      className="membership-list-item-apply-button"
                      type="submit"
                      onClick={() =>
                        handleApplyForMembership(plan, comments.comments)
                      }
                      loading={isLoading}
                      style={{ paddingLeft: '5px' }}
                    >
                      {t('Apply')}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default MembershipModal;
