import { FC } from 'react';
import { ISvgProps } from 'shared/svg/interfaces';

const defaultProps: ISvgProps = {
  viewBox: '0 0 58 58',
  pathClassName: 'st0',
  fill: 'black',
};

export const SvgIconUserAccount: FC = (props: ISvgProps) => {
  props = { ...defaultProps, ...props };
  const { pathClassName, ...svgProps } = props;

  return (
    <svg {...svgProps}>
      <path
        className={pathClassName}
        d="M28 14C26.2694 14 24.5777 14.5132 23.1388 15.4746C21.6998 16.4361 20.5783 17.8027 19.9161 19.4015C19.2538 21.0004 19.0805 22.7597 19.4181 24.457C19.7558 26.1544 20.5891 27.7135 21.8128 28.9372C23.0365 30.1609 24.5956 30.9943 26.293 31.3319C27.9903 31.6695 29.7496 31.4962 31.3485 30.8339C32.9473 30.1717 34.3139 29.0502 35.2754 27.6112C36.2368 26.1723 36.75 24.4806 36.75 22.75C36.75 20.4294 35.8281 18.2038 34.1872 16.5628C32.5462 14.9219 30.3207 14 28 14Z"
      />
      <path
        className={pathClassName}
        d="M28 3.5C23.1544 3.5 18.4175 4.9369 14.3885 7.62899C10.3595 10.3211 7.21931 14.1475 5.36496 18.6243C3.51062 23.101 3.02543 28.0272 3.97077 32.7797C4.91611 37.5322 7.24951 41.8977 10.6759 45.3241C14.1023 48.7505 18.4678 51.0839 23.2203 52.0292C27.9728 52.9746 32.899 52.4894 37.3758 50.635C41.8525 48.7807 45.6789 45.6405 48.371 41.6115C51.0631 37.5825 52.5 32.8456 52.5 28C52.4926 21.5045 49.909 15.2771 45.3159 10.6841C40.7229 6.09103 34.4955 3.50741 28 3.5ZM41.986 43.6205C41.9511 41.3251 41.0159 39.1352 39.3819 37.5228C37.7478 35.9103 35.5457 35.0043 33.25 35H22.75C20.4543 35.0043 18.2522 35.9103 16.6182 37.5228C14.9841 39.1352 14.0489 41.3251 14.014 43.6205C10.8405 40.7868 8.60254 37.056 7.59647 32.9222C6.5904 28.7883 6.86368 24.4464 8.38013 20.4713C9.89657 16.4962 12.5846 13.0754 16.0884 10.662C19.5922 8.24857 23.7464 6.9563 28.0009 6.9563C32.2554 6.9563 36.4096 8.24857 39.9134 10.662C43.4171 13.0754 46.1052 16.4962 47.6216 20.4713C49.1381 24.4464 49.4114 28.7883 48.4053 32.9222C47.3992 37.056 45.1613 40.7868 41.9878 43.6205H41.986Z"
      />
    </svg>
  );
};
