import React from 'react';
import { ISvgProps } from 'shared/svg/interfaces';

const SvgIconCar: React.FC<ISvgProps> = (props) => {
  // SVG path data for the car icon
  const pathData =
    'M16.3333 32C16.3333 34.25 14.4583 36 12.3333 36C10.0833 36 8.33334 34.25 8.33334 32C8.33334 29.875 10.0833 28 12.3333 28C14.4583 28 16.3333 29.875 16.3333 32ZM12.3333 30C11.2083 30 10.3333 31 10.3333 32C10.3333 33.125 11.2083 34 12.3333 34C13.3333 34 14.3333 33.125 14.3333 32C14.3333 31 13.3333 30 12.3333 30Z';

  return (
    <svg {...props}>
      <path className={props.pathClassName} d={pathData} />
    </svg>
  );
};

export default SvgIconCar;
