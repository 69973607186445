import React, { FC, useEffect, useState, useCallback } from 'react';
import { Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { parseTemplate } from 'url-template';
import { t } from 'i18next';

import { axiosGet, axiosPost } from 'helpers';
import { getApiHrefByRel } from 'helpers/api-links-helper';

import { Loader } from 'components/loader/loader';
import ModalWrapper from 'components/modal/ModalWrapper';

import './SubscriptionModal.scss';
import {
  SubscriptionPlan,
  SubscriptionPlansApiResponse,
} from 'interfaces/subscription';
import { Heading6 } from 'components/headings/heading6/heading6';
import { Button } from 'components/clickers/button/button';
import { Heading2 } from 'components/headings/heading2/heading2';

interface Props {
  isOpen: boolean;
  subscriptionPlansLink: string;
  setIsOpen: (val: boolean) => void;
  refreshSubscriptions: () => Promise<void>;
}

const SubscriptionModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  subscriptionPlansLink,
  refreshSubscriptions,
}) => {
  const [subscriptions, setSubscriptions] = useState<SubscriptionPlan[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleApplyForSubscription = async (plan: SubscriptionPlan) => {
    setIsLoading(true);
    try {
      const subscriptionSelfLink = getApiHrefByRel(plan.Links, 'self');
      const response = await axiosGet(subscriptionSelfLink);
      const selfResponse = response?.data as SubscriptionPlan;
      const subscriptionApplyLink = getApiHrefByRel(
        selfResponse.Links,
        'subscribe',
      );

      const currentDate = new Date();
      const startTime = currentDate.toISOString();

      const subscribeResponse = await axiosPost(subscriptionApplyLink, {
        StartTime: startTime,
        CompleteUrl: '/dashboard/subscriptions?is_completed=true',
        CancelUrl: '/dashboard/subscriptions?is_cancelled=true',
        TermsOfServiceUrl: '/terms-and-conditions',
      });

      if (subscribeResponse?.status && subscribeResponse.status > 200) {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // 3 sec
        await refreshSubscriptions();
        setIsOpen(false);
      }
    } catch (error) {
      console.error('Error applying for subscription:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSubscriptionPlans = useCallback(async () => {
    try {
      setIsLoading(true);
      const urlTemplate = parseTemplate(decodeURI(subscriptionPlansLink));
      const apiLink = urlTemplate.expand({ pagesize: 50, page: 0 });

      const response = await axiosGet(apiLink);
      const data = response?.data as SubscriptionPlansApiResponse;
      setSubscriptions(data.Items as SubscriptionPlan[]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching Subscription plans:', error);
    }
  }, [subscriptionPlansLink]);

  useEffect(() => {
    if (isOpen && subscriptionPlansLink) {
      getSubscriptionPlans();
    }
  }, [isOpen, subscriptionPlansLink, getSubscriptionPlans]);

  return (
    <ModalWrapper open={isOpen} onClose={() => setIsOpen(false)}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className="main"
      >
        <Grid item xs={12} textAlign="center" paddingBottom={3}>
          <Heading2>{t('Subscription plans')}</Heading2>
        </Grid>

        <Grid item xs={2} textAlign="end" className="close-icon">
          <CloseIcon
            style={{ cursor: 'pointer' }}
            fontSize="small"
            onClick={() => setIsOpen(false)}
          />
        </Grid>
        {isLoading && (
          <Grid
            container
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              position: 'sticky',
              top: 0,
              marginTop: '10px',
              marginBottom: '20px',
            }}
          >
            <Loader loading={isLoading} />
          </Grid>
        )}
        <Grid item xs={12} className="subscription">
          {subscriptions?.map((subscription) => (
            <Grid
              key={subscription.SubscriptionPlanGuid}
              className="membership-list-item"
              style={{ padding: '15px' }}
            >
              <Grid>
                <Heading6>{subscription.Name}</Heading6>
                <Typography component="p" className="secondary-text">
                  {subscription.TotalAmount} kr
                </Typography>
              </Grid>
              <Button
                className="membership-list-item-apply-button"
                type="submit"
                onClick={() => handleApplyForSubscription(subscription)}
                loading={isLoading}
                style={{
                  fontSize: '16px',
                  display: 'flex',
                  padding: '5px 10px',
                  border: '2px solid black',
                  justifyContent: 'center',
                }}
                disabled={isLoading}
              >
                {t('Subscribe')}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default SubscriptionModal;
