// import { configureStore } from '@reduxjs/toolkit';
// import reducer, { middleware } from './reducer';
// import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// export const store = configureStore({
//   reducer,
//   middleware,
// });

// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
// const { dispatch } = store;
// export { dispatch };

// export const useAppDispatch = () => useDispatch<AppDispatch>();
// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import { configureStore } from '@reduxjs/toolkit';
import reducer, { middleware } from './reducer';
// import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
  reducer,
  middleware,
});

// Create a persisted store

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;
export { dispatch };
